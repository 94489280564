import React from 'react';
import { Typography, TextField } from '@mui/material';

import { useStyles } from '../style';

export default function RainPower(props) {
  const classes = useStyles();
  const { rainPower, setRainPower } = props

  const setRainPowerValue = (index, value) => {
    var newRainPower = [...rainPower]
    newRainPower[index] = { ...rainPower[index], value }
    setRainPower(newRainPower)
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        עוצמות גשם לתכנון
      </Typography>

      <TextField
        required
        className={classes.textField}
        id="address1"
        name="address1"
        label="מספר איזור"
        type='number'
        fullWidth
        autoComplete="shipping address-line1"
        variant="standard"
        onChange={(e) => setRainPowerValue(0, parseInt(e.target.value))}
        value={rainPower[0].value}
      />
      {/* <TextField
        required
        className={classes.textField}
        id="address1"
        name="address1"
        label="תיאור"
        fullWidth
        autoComplete="shipping address-line1"
        variant="standard"
        onChange={(e) => setRainPowerValue(1, e.target.value)}
        value={rainPower[1].value}
      /> */}
    </React.Fragment >
  );
}