import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';

import { useStyles } from '../style';

export default function ConclutionsFinal(props) {
  const { conclutionsFinal, setConclutionsFinal } = props
  const classes = useStyles();


  const setConclutionsFinalValue = (index, value) => {
    var newConclutions = [...conclutionsFinal]
    newConclutions[index] = { ...conclutionsFinal[index], value }
    setConclutionsFinal(newConclutions)
  }

  const conclutionsFinalTextFields = () => {
    return (conclutionsFinal.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setConclutionsFinalValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        סיכום והנחיות לתכנון
      </Typography>
      <Grid container spacing={3}>
        {conclutionsFinalTextFields()}
      </Grid>
      <TextFieldButtons
        arr={conclutionsFinal}
        setArr={setConclutionsFinal}
      />
    </React.Fragment>
  );
}