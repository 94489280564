import React, { useMemo, useState } from 'react';
import MyTable from './MyTable';
import { Grid, Typography, TextField } from '@mui/material';
import { useStyles } from '../style';
import convertBase64 from '../Utils';

export default function Sufficiency(props) {
    const classes = useStyles();

    const { sufficiency, setSufficiency, cellValues, setCellValues, sufficiencyTableImage, setSufficiencyTableImage, region = 6, value1, value2 } = props
    const [colHeader] = useState('אגן')
    const [headers] = useState(['A (דונם)', 'I (מ"מ/שעה) 20% 10min', 'I (מ"מ/שעה) 20% 60min', '(-) C', 'Q (מ"ק/שעה) 20% 60min', 'Q (מ"ק/שעה) 20% 60min']);
    const [rowTitles] = useState(['היטל מגרש', 'חזית בניינים', 'כלל המגרש']);

    const handleChange = async (e) => {
        const base64 = await convertBase64(e.target.files[0])
        setSufficiencyTableImage(base64);
    }

    const setSufficiencyValue = (index, value) => {
        var newSufficiency = [...sufficiency]
        newSufficiency[index] = { ...sufficiency[index], value }
        setSufficiency(newSufficiency)
    }

    const sufficiencyTextFields = () => {
        return (sufficiency.map((item, index) => (
            <Grid item xs={12} key={index}>
                <TextField
                    required
                    className={classes.textField}
                    key={index}
                    id="address1"
                    name="address1"
                    label="תיאור"
                    fullWidth
                    autoComplete="shipping address-line1"
                    variant="standard"
                    onChange={(e) => setSufficiencyValue(index, e.target.value)}
                    value={item.value}
                />
            </Grid>
        )))
    }

    useMemo(() => {
        if (region === 6) {
            cellValues[0].value0 = 1.00
            cellValues[1].value1 = 122
            cellValues[2].value2 = 36
            cellValues[7].value7 = 122
            cellValues[8].value8 = 36
            cellValues[6].value6 = 1.99
        }
        cellValues[3].value3 = value1
        cellValues[6].value6 = value2

        cellValues[4].value4 = parseFloat(parseFloat(cellValues[3].value3) * parseFloat(cellValues[1].value1) * parseFloat(cellValues[0].value0) / 3600).toFixed(2)
        cellValues[5].value5 = parseFloat(parseFloat(cellValues[3].value3) * parseFloat(cellValues[2].value2) * parseFloat(cellValues[0].value0) / 3600).toFixed(2)

        cellValues[10].value10 = parseFloat(parseFloat(cellValues[9].value9) * parseFloat(cellValues[7].value7) * parseFloat(cellValues[6].value6) / 3600).toFixed(2)
        cellValues[11].value11 = parseFloat(parseFloat(cellValues[9].value9) * parseFloat(cellValues[8].value8) * parseFloat(cellValues[6].value6) / 3600).toFixed(2)
        cellValues[12].value12 = parseFloat(cellValues[0].value0) + parseFloat(cellValues[6].value6)

        cellValues[16].value16 = parseFloat(cellValues[4].value4) + parseFloat(cellValues[10].value10)
        cellValues[17].value17 = parseFloat(cellValues[5].value5) + parseFloat(cellValues[11].value11)


    }, [cellValues])

    return (
        <React.Fragment >
            <Typography variant="h5" gutterBottom>
                ספיקות תכן
            </Typography>
            <Grid container spacing={3}>
                {sufficiencyTextFields()}
            </Grid>
            {/* <MyTable
                colHeader={colHeader}
                headers={headers}
                rowTitles={rowTitles}
                cellValues={cellValues}
                setCellValues={setCellValues}
            ></MyTable> */}
            <h3> טבלה ספיקות תכן</h3>
            <input type="file" onChange={handleChange} />
            <img alt="" src={sufficiencyTableImage} />
        </React.Fragment>
    );
}