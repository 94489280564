import React, { useEffect } from 'react';
import { Grid, Typography, TextField } from '@mui/material';

import { useStyles } from '../style';

export default function Probability(props) {
  const { probability, setProbability, region } = props
  const classes = useStyles();

  const setProbabilityValue = (index, value) => {
    var newProbability = [...probability]
    newProbability[index] = { ...probability[index], value }
    setProbability(newProbability)
  }

  const probabilityTextFields = () => {
    return (probability.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="אחוז תיעול עירוני"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setProbabilityValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  useEffect(() => {
    var newProbability = [...probability]

    if (region === 6) {
      newProbability[0].value = "20"
    }

    setProbability(newProbability)

  }, [])

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        הסתברות תכן
      </Typography>
      <Grid container spacing={3}>
        {probabilityTextFields()}
      </Grid>

    </React.Fragment>
  );
}