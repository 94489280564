import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';

import { useStyles } from '../style';

export default function Resources(props) {

  const { resources, setResources } = props
  const classes = useStyles();

  const setResourcesValue = (index, value) => {
    var newResources = [...resources]
    newResources[index] = { ...resources[index], value }
    setResources(newResources)
  }

  const resourcesTextFields = () => {
    return (resources.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setResourcesValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }


  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        מקורות
      </Typography>
      <Grid container spacing={3}>
        {resourcesTextFields()}
      </Grid>
      <TextFieldButtons
        arr={resources}
        setArr={setResources}
      />
    </React.Fragment>
  );
}