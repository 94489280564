import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { useStyles } from '../style';

export default function SuggestedSystem(props) {
    const classes = useStyles();

    const { suggestedSystem, setSuggestedSystem } = props

    const setSuggestedSystemValue = (index, value, semiIndex) => {
        var newSuggestedSystem = [...suggestedSystem]
        newSuggestedSystem[semiIndex][index] = { ...suggestedSystem[semiIndex][index], value }
        setSuggestedSystem(newSuggestedSystem)
    }

    const suggestedSystemTextFields = (suggestedSystemArr, semiIndex) => {
        return (suggestedSystemArr.map((item, index) => (
            <Grid item xs={12} key={index}>
                <TextField
                    required
                    className={classes.textField}
                    key={index}
                    id="address1"
                    name="address1"
                    label="תיאור"
                    fullWidth
                    autoComplete="shipping address-line1"
                    variant="standard"
                    onChange={(e) => setSuggestedSystemValue(index, e.target.value, semiIndex)}
                    value={item.value}
                />
            </Grid>
        )))
    }

    return (
        <React.Fragment >
            <Typography variant="h5" gutterBottom>
                מערכת מוצעת לניהול נגר
            </Typography>
            <Grid container spacing={3}>
                {suggestedSystemTextFields(suggestedSystem[0], 0)}
                {suggestedSystemTextFields(suggestedSystem[1], 1)}
                {suggestedSystemTextFields(suggestedSystem[2], 2)}
                {suggestedSystemTextFields(suggestedSystem[3], 3)}
                {suggestedSystemTextFields(suggestedSystem[4], 4)}
                {suggestedSystemTextFields(suggestedSystem[5], 5)}
                {suggestedSystemTextFields(suggestedSystem[6], 6)}
                {suggestedSystemTextFields(suggestedSystem[7], 7)}
                {suggestedSystemTextFields(suggestedSystem[8], 8)}



            </Grid>



        </React.Fragment>
    );
}