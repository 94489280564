import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';
import convertBase64 from '../Utils';

import { useStyles } from '../style';

export default function Tama1(props) {
  const { tama1, setTama1, tama1Image, setTama1Image } = props
  const classes = useStyles();

  const handleChange = async (e) => {
    const base64 = await convertBase64(e.target.files[0])
    setTama1Image(base64);
  }

  const setTama1Value = (index, value) => {
    var newTama1 = [...tama1]
    newTama1[index] = { ...tama1[index], value }
    setTama1(newTama1)
  }

  const tama1TextFields = () => {
    return (tama1.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setTama1Value(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        תמא 1
      </Typography>
      <Grid container spacing={3}>
        {tama1TextFields()}
        <Grid item xs={12}>
          <input type="file" onChange={handleChange} />
          <img alt="" src={tama1Image} />
        </Grid>
      </Grid>
      <TextFieldButtons
        arr={tama1}
        setArr={setTama1}
      />
    </React.Fragment>
  );
}