import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';

import { useStyles } from '../style';

export default function GroundWaterLevel(props) {
  const { groundWaterLevel, setGroundWaterLevel } = props
  const classes = useStyles();

  const setGroundWaterLevelValue = (index, value) => {
    var newGroundWaterLevel = [...groundWaterLevel]
    newGroundWaterLevel[index] = { ...groundWaterLevel[index], value }
    setGroundWaterLevel(newGroundWaterLevel)
  }

  const groundWaterLevelTextFields = () => {
    return (groundWaterLevel.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setGroundWaterLevelValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        מפלס מי תהום
      </Typography>
      <Grid container spacing={3}>
        {groundWaterLevelTextFields()}
      </Grid>
      <TextFieldButtons
        arr={groundWaterLevel}
        setArr={setGroundWaterLevel}
      />
    </React.Fragment>
  );
}