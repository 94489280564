import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';

import { useStyles } from '../style';

export default function Background(props) {
  const { backgrounds, setBackgrounds } = props
  const classes = useStyles();

  const setBackgroundsValue = (index, value) => {
    var newBackgrounds = [...backgrounds]
    newBackgrounds[index] = { ...backgrounds[index], value }
    setBackgrounds(newBackgrounds)
  }

  const backgroundsTextFields = () => {
    return (backgrounds.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          variant="standard"
          onChange={(e) => setBackgroundsValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        רקע
      </Typography>
      <Grid container spacing={3}>
        {backgroundsTextFields()}
      </Grid>

      <TextFieldButtons
        arr={backgrounds}
        setArr={setBackgrounds}
      />
    </React.Fragment>
  );
}