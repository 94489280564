import * as React from 'react';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function ImageButtons(props) {
    const { arr, setArr } = props

    const pushTextFieldAndImage = () => {
        var newArr = [...arr]
        newArr.push([{ value0: '' }, { value1: '' }])
        setArr(newArr)
    }

    const popTextFieldAndImage = () => {
        var newArr = [...arr]
        newArr.pop()
        setArr(newArr)
    }

    return (
        <React.Fragment >
            <Box sx={{ '& > button': { m: 2 } }} >
                <LoadingButton
                    color='error'
                    onClick={popTextFieldAndImage}
                    startIcon={<RemoveCircleIcon />}
                    variant="contained"
                >
                    <span >{'מחק תמונה אחרונה'}</span>
                </LoadingButton>

                <LoadingButton
                    color="success"
                    onClick={pushTextFieldAndImage}
                    startIcon={<AddCircleIcon />}
                    variant="contained"
                >
                    <span >{'הוסף תמונה חדש'}</span>
                </LoadingButton>

            </Box>
        </React.Fragment>
    );
}