import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Copyright() {
  <Typography variant="body2" color="text.secondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://repgen.co.il/">
      RepGen
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
}