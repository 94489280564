import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    textField: {
      '& .MuiInputBase-input': {
        textAlign: 'right',
        direction: 'rtl',
      },
      '& .MuiInputLabel-root': {
        right: 0,
        left: 'auto',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(0.75)',
      },
    },
  }));
