import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableContainer, TextField } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const MyTable = (props) => {
  const { colHeader, headers, rowTitles, cellValues, setCellValues } = props

  const handleCellValueChange = (index, value) => {
    var newCellValues = [...cellValues]

    switch (index) {
      case 0:
        newCellValues[index].value0 = value

        break;
      case 1:
        newCellValues[index].value1 = value

        break;
      case 2:
        newCellValues[index].value2 = value

        break;
      case 3:
        newCellValues[index].value3 = value

        break;
      case 4:
        newCellValues[index].value4 = value

        break;
      case 5:
        newCellValues[index].value5 = value

        break;
      case 6:
        newCellValues[index].value6 = value

        break;
      case 7:
        newCellValues[index].value7 = value

        break;
      case 8:
        newCellValues[index].value8 = value

        break;
      case 9:
        newCellValues[index].value9 = value

        break;
      case 10:
        newCellValues[index].value10 = value

        break;
      case 11:
        newCellValues[index].value11 = value

        break;
      case 12:
        newCellValues[index].value12 = value

        break;
      case 13:
        newCellValues[index].value13 = value

        break;
      case 14:
        newCellValues[index].value14 = value

        break;
      case 15:
        newCellValues[index].value15 = value

        break;
      case 16:
        newCellValues[index].value16 = value

        break;
      case 17:
        newCellValues[index].value17 = value

        break;
      case 18:
        newCellValues[index].value18 = value

        break;
      case 19:
        newCellValues[index].value19 = value

        break;
      case 20:
        newCellValues[index].value20 = value

        break;
      case 21:
        newCellValues[index].value21 = value

        break;
      case 22:
        newCellValues[index].value22 = value

        break;
      case 23:
        newCellValues[index].value23 = value

        break;

      default:
        break;
    }

    setCellValues(newCellValues)
  };

  const getValueByIndex = (index) => {
    switch (index) {
      case 0:
        return cellValues[index].value0
      case 1:
        return cellValues[index].value1

      case 2:
        return cellValues[index].value2

      case 3:
        return cellValues[index].value3

      case 4:
        return cellValues[index].value4

      case 5:
        return cellValues[index].value5

      case 6:
        return cellValues[index].value6

      case 7:
        return cellValues[index].value7

      case 8:
        return cellValues[index].value8

      case 9:
        return cellValues[index].value9

      case 10:
        return cellValues[index].value10

      case 11:
        return cellValues[index].value11

      case 12:
        return cellValues[index].value12

      case 13:
        return cellValues[index].value13

      case 14:
        return cellValues[index].value14

      case 15:
        return cellValues[index].value15

      case 16:
        return cellValues[index].value16

      case 17:
        return cellValues[index].value17

      case 18:
        return cellValues[index].value18

      case 19:
        return cellValues[index].value19

      case 20:
        return cellValues[index].value20
      case 21:
        return cellValues[index].value21
      case 22:
        return cellValues[index].value22
      case 23:
        return cellValues[index].value23

      default:
        return 0
    }
  };

  return (
    <TableContainer component={Paper} style={{ direction: 'rtl' }}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">{colHeader}</StyledTableCell>
            {headers.map((item, headerIndex) => (
              <StyledTableCell align="center" key={headerIndex}>{item}</StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rowTitles.map((rowTitle, rowIndex) => (
            <StyledTableRow key={rowTitle}>
              <StyledTableCell align="center">{rowTitle}</StyledTableCell>
              {headers.map((item, headerIndex) => (
                <StyledTableCell align="center" key={headerIndex}>
                  <TextField
                    type="number"
                    // pattern="[0-9]*"

                    value={getValueByIndex((rowIndex * headers.length) + headerIndex)}
                    onChange={(event) =>
                      handleCellValueChange(((rowIndex * headers.length) + headerIndex), parseFloat(event.target.value))
                    }
                  />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
