import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import convertBase64 from '../Utils';

import { useStyles } from '../style';
import ImageButtons from './ImageButtons';

export default function MorePictures(props) {
  const { picturesAndTitles, setPicturesAndTitles } = props
  const classes = useStyles();

  const handleChange = async (e) => {
    const base64 = await convertBase64(e)
    return base64
  }

  const setPicturesAndTitlesValue = async (index, value, isImage) => {
    var newPicturesAndTitles = picturesAndTitles

    if (isImage) {
      const image = await handleChange(value)
      newPicturesAndTitles[index][isImage].value1 = image
    }
    else
      newPicturesAndTitles[index][isImage].value0 = value

    setPicturesAndTitles(newPicturesAndTitles)
  }

  const picturesAndTitlesTextFields = () => {
    return (picturesAndTitles.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="כותרת איור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setPicturesAndTitlesValue(index, e.target.value, 0)}
          value={item.value}
        />
        <input type="file" onChange={(e) => setPicturesAndTitlesValue(index, e.target.files[0], 1)} />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        נספחים ותמונות
      </Typography>
      <Grid container spacing={3}>
        {picturesAndTitlesTextFields()}
      </Grid>
      <ImageButtons
        arr={picturesAndTitles}
        setArr={setPicturesAndTitles}
      />
    </React.Fragment>
  );
}