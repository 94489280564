import React, { useMemo, useState, useEffect } from 'react';
import MyTable from './MyTable';
import { Grid, Typography, TextField } from '@mui/material';
import { useStyles } from '../style';
import convertBase64 from '../Utils';

export default function ManageRunoff(props) {
    const classes = useStyles();

    const { manageRunoff, setManageRunoff, cellValues, setCellValues, manageRunoffTableImage, setManageRunoffTableImage, region } = props
    const [colHeader] = useState('סוג התכסית')
    const [headers] = useState(['שטח (דונם)', 'אחוז התכסית מכלל המגרש (%)', 'עוצמת הגשם', 'נפח מי נגר הנוצרים ב10 דקות (מ"ק)', 'נפח מי נהגר המנוהלים ב10 דקות (מ"ק)', 'אחוז מי נגר מנוהלים (%)']);
    const [rowTitles] = useState(['שטחי גגות ומרפסות', 'שטחים מעל מרתף ומחוץ לגגות ומרפסות', 'שטחי חלחול', 'סה"כ']);

    const handleChange = async (e) => {
        const base64 = await convertBase64(e.target.files[0])
        setManageRunoffTableImage(base64);
    }

    const setManageRunoffValue = (index, value, semiIndex) => {
        var newManageRunoff = [...manageRunoff]
        newManageRunoff[semiIndex][index] = { ...manageRunoff[semiIndex][index], value }
        setManageRunoff(newManageRunoff)
    }

    const manageRunoffTextFields = (manageRunoffArr, semiIndex) => {
        return (manageRunoffArr.map((item, index) => (
            <Grid item xs={12} key={index}>
                <TextField
                    required
                    className={classes.textField}
                    key={index}
                    id="address1"
                    name="address1"
                    label="תיאור"
                    fullWidth
                    autoComplete="shipping address-line1"
                    variant="standard"
                    onChange={(e) => setManageRunoffValue(index, e.target.value, semiIndex)}
                    value={item.value}
                />
            </Grid>
        )))
    }


    useMemo(() => {


        cellValues[18].value18 = parseFloat(parseFloat(cellValues[0].value0) + parseFloat(cellValues[6].value6) + parseFloat(cellValues[12].value12)).toFixed(2)
        cellValues[19].value19 = parseFloat(parseFloat(cellValues[1].value1) + parseFloat(cellValues[7].value7) + parseFloat(cellValues[13].value13)).toFixed(2)

        cellValues[3].value3 = parseFloat(parseFloat(cellValues[0].value0) * parseFloat(cellValues[2].value2) / 6).toFixed(2)
        cellValues[9].value9 = parseFloat(parseFloat(cellValues[6].value6) * parseFloat(cellValues[8].value8) / 6).toFixed(2)
        cellValues[15].value15 = parseFloat(parseFloat(cellValues[3].value3) + parseFloat(cellValues[9].value9)).toFixed(2)

        cellValues[5].value5 = parseFloat(cellValues[4].value4 / cellValues[3].value3 * 100).toFixed(2)
        cellValues[11].value11 = parseFloat(cellValues[10].value10 / cellValues[9].value9 * 100).toFixed(2)
        cellValues[17].value17 = parseFloat(cellValues[16].value16 / cellValues[15].value15 * 100).toFixed(2)

        cellValues[21].value21 = parseFloat(cellValues[9].value9) + parseFloat(cellValues[15].value15)
        cellValues[22].value22 = parseFloat(cellValues[4].value4) + parseFloat(cellValues[10].value10) + parseFloat(cellValues[16].value16)
        cellValues[23].value23 = parseFloat(cellValues[22].value22 / cellValues[21].value21 * 100).toFixed(2)

    }, [cellValues])

    useEffect(() => {
        var newCellValues = [...cellValues]

        if (region === 6) {
            newCellValues[0].value0 = 0.6
            newCellValues[6].value6 = 0.25
            newCellValues[12].value12 = 0.15

            newCellValues[1].value1 = newCellValues[0].value0 * 100
            newCellValues[7].value7 = newCellValues[6].value6 * 100
            newCellValues[13].value13 = newCellValues[12].value12 * 100

            newCellValues[2].value2 = newCellValues[8].value8 = newCellValues[14].value14 = newCellValues[20].value20 = 122
        }

        setCellValues(newCellValues)
    }, [])

    return (
        <React.Fragment >
            <Typography variant="h5" gutterBottom>
                ניהול נגר לפי תקן בניה ירוקה 5281
            </Typography>
            <Grid container spacing={3}>
                {manageRunoffTextFields(manageRunoff[0], 0)}

            </Grid>
            {/* <MyTable
                colHeader={colHeader}
                headers={headers}
                rowTitles={rowTitles}
                cellValues={cellValues}
                setCellValues={setCellValues}
            ></MyTable> */}
            <Grid margin={5}>
                <h3> טבלת ניהול נגר</h3>
                <input type="file" onChange={handleChange} />
                <img alt="" src={manageRunoffTableImage} />
            </Grid>
            {manageRunoffTextFields(manageRunoff[1], 1)}

        </React.Fragment>
    );
}