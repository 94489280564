import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs, { Dayjs } from 'dayjs';

import { useStyles } from '../style';

export default function Gate(props) {

  const classes = useStyles();
  // const [value, setValue] = React.useState(dayjs);

  const { setHead, setDate, setAddress, setCompanyName, setClientName, setAdrichal, setProjectName, setCity, setLocation1, setLocation2, head, date,
    address,
    companyName,
    clientName,
    adrichal,
    projectName,
    city,
    location1,
    location2, } = props;

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        שער
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            className={classes.textField}
            id="כותרת"
            name="כותרת"
            label="כותרת"
            fullWidth
            variant="standard"
            onChange={(e) => setHead(e.target.value)}
            value={head}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="address1"
            name="address1"
            label="תאריך"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
        </Grid>
        <Grid item xs={12} sm={6} >

          <TextField
            required
            className={classes.textField}
            id="כתובת"
            name="כתובת"
            label="כתובת"
            fullWidth
            variant="standard"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="עיר"
            name="עיר"
            label="עיר"
            fullWidth
            variant="standard"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />

        </Grid>
        <Grid item xs={12} sm={6} >
          <TextField
            required
            className={classes.textField}
            id="שם פרויקט"
            name="שם פרויקט"
            label="שם פרויקט"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            className={classes.textField}
            id="address2"
            name="address2"
            label="שם החברה שלנו"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="city"
            name="city"
            label="שם מזמין"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            onChange={(e) => setClientName(e.target.value)}
            value={clientName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="state"
            name="state"
            label="שם אדריכל"
            fullWidth
            variant="standard"
            onChange={(e) => setAdrichal(e.target.value)}
            value={adrichal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="zip"
            name="zip"
            label="מיקום - גוש"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={(e) => setLocation1(e.target.value)}
            value={location1}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            className={classes.textField}
            id="מיקום - חלקה"
            name="מיקום - חלקה"
            label="מיקום - חלקה"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
            onChange={(e) => setLocation2(e.target.value)}
            value={location2}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label=""
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}