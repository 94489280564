import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';
import convertBase64 from '../Utils';

import { useStyles } from '../style';

export default function Topography(props) {
  const { topography, setTopography, topographyImage, setTopographyImage } = props
  const classes = useStyles();

  const handleChange = async (e) => {
    const base64 = await convertBase64(e.target.files[0])
    setTopographyImage(base64);
  }

  const setTopographyValue = (index, value) => {
    var newTopography = [...topography]
    newTopography[index] = { ...topography[index], value }
    setTopography(newTopography)
  }

  const topographyTextFields = () => {
    return (topography.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setTopographyValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        טופוגרפיה
      </Typography>
      <Grid container spacing={3}>
        {topographyTextFields()}
        <Grid item xs={12}>
          <input type="file" onChange={handleChange} />
          <img alt="" src={topographyImage} />
        </Grid>
      </Grid>
      <TextFieldButtons
        arr={topography}
        setArr={setTopography}
      />
    </React.Fragment>
  );
}