import * as React from 'react';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';


export default function TextFieldButtons(props) {
  const { arr, setArr } = props
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const pushTextField = () => {
    var newArr = [...arr]
    newArr.push('')
    setArr(newArr)
  }

  const popTextField = () => {
    setOpen(false);

    var newArr = [...arr]
    newArr.pop()
    setArr(newArr)
  }


  return (
    <React.Fragment >
      <Box sx={{ '& > button': { m: 2 } }} >
        <Dialog
          dir={"rtl"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"האם ברצונך למחוק תיאור אחרון?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>ביטול</Button>
            <Button onClick={popTextField} autoFocus>
              אישור
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingButton
          color='error'
          onClick={handleOpen}
          startIcon={<RemoveCircleIcon />}
          variant="contained"
        >
          <span >{'מחק תיאור אחרון'}</span>
        </LoadingButton>

        <LoadingButton
          color="success"
          onClick={pushTextField}
          startIcon={<AddCircleIcon />}
          variant="contained"
        >
          <span >{'הוסף תיאור חדש'}</span>
        </LoadingButton>

      </Box>
    </React.Fragment>
  );
}