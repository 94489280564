import * as React from 'react';
import Checkout from './Components/Checkout';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import './App.css';

function App() {

  const [connected, setConnected] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [steps] = React.useState(
    [
      { text: 'שער', icon: <InboxIcon /> },
      { text: 'רקע', icon: <DraftsIcon /> },
      { text: 'מקורות', icon: <InboxIcon /> },
      { text: 'מסקנות', icon: <DraftsIcon /> },
      { text: 'טופוגרפיה', icon: <InboxIcon /> },
      { text: 'מפלס מי תהום', icon: <DraftsIcon /> },
      { text: 'תמ"א 1', icon: <InboxIcon /> },
      { text: 'מערכת ניקוז קיימת', icon: <DraftsIcon /> },
      { text: 'הסתברות תכן', icon: <DraftsIcon /> },
      { text: 'עוצמות גשם לתכנון', icon: <InboxIcon /> },
      { text: 'מקדם נגר', icon: <InboxIcon /> },
      { text: 'ספיקות תכן', icon: <DraftsIcon /> },
      //{ text: 'חישוב מי נגר עודף לפי ספיקות התכן', icon: <DraftsIcon /> },
      //{ text: 'הנחיות רשות המים להחדרת מי נגר', icon: <InboxIcon /> },
      { text: 'ניהול נגר לפי תקן בניה ירוקה 5281', icon: <DraftsIcon /> },
      { text: 'מערכת מוצעת לניהול נגר', icon: <DraftsIcon /> },
      { text: 'סיכום והנחיות לתכנון', icon: <DraftsIcon /> },
      { text: 'נספחים ותמונות', icon: <DraftsIcon /> },
    ])

  const connectedApp = () => {
    return (
      <div className="App">
        <Checkout
          step={step}
          setStep={setStep}
          steps={steps}
          connected={connected}
          setConnected={setConnected}
        ></Checkout>
      </div>
    )
  }

  return (
    connectedApp()
  );
}

export default App;
