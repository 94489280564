import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';

import { useStyles } from '../style';
import MyTable from './MyTable';
import convertBase64 from '../Utils';

export default function RunoffCoefficient(props) {
  const classes = useStyles();
  const { runoffCoefficient, setRunoffCoefficient, runoffCoefficientImage, setRunoffCoefficientImage, cellValues, setCellValues, cellValues2, setCellValues2,
    runoffCoefficientTableImage1, setRunoffCoefficientTableImage1, runoffCoefficientTableImage2, setRunoffCoefficientTableImage2, region } = props

  const [colHeader] = useState('תכסית')
  const [headers] = useState(['שטח (דונם)', 'חלק יחסי ', 'מקדם נגר', 'מקדם נגר משוקלל']);
  const [rowTitles] = useState(['גגות וקירוי', 'תקרת מרתף מחוץ לגגות וקירוי', 'פתוח מחלחל', 'סה"כ']);

  const [colHeader2] = useState('מגדל')
  const [headers2] = useState(["גובה (מ')", "רוחב (מ')", '(דונם) A']);
  const [rowTitles2] = useState(['A', 'B', 'סה"כ']);

  const handleChange = async (e) => {
    const base64 = await convertBase64(e.target.files[0])
    setRunoffCoefficientImage(base64);
  }

  const handleChangeImage1 = async (e) => {
    const base64 = await convertBase64(e.target.files[0])
    setRunoffCoefficientTableImage1(base64);
  }

  // const handleChangeImage2 = async (e) => {
  //   const base64 = await convertBase64(e.target.files[0])
  //   setRunoffCoefficientTableImage2(base64);
  // }

  const setRunoffCoefficientValue = (index, value, semiIndex) => {
    var newRunoffCoefficient = [...runoffCoefficient]
    newRunoffCoefficient[semiIndex][index] = { ...runoffCoefficient[semiIndex][index], value }
    setRunoffCoefficient(newRunoffCoefficient)
  }

  const runoffCoefficientTextFields = (runoffCoefficientArr, semiIndex) => {
    return (runoffCoefficientArr.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setRunoffCoefficientValue(index, e.target.value, semiIndex)}
          value={item.value}
        />
      </Grid>
    )))
  }

  useEffect(() => {
    var newCellValues = [...cellValues]
    var newCellValues2 = [...cellValues2]

    if (region === 6) {
      newCellValues[0].value0 = 0.6
      newCellValues[4].value4 = 0.25
      newCellValues[8].value8 = 0.15

      newCellValues2[0].value0 = 50
      newCellValues2[1].value1 = 20
      newCellValues2[3].value3 = 45
      newCellValues2[4].value4 = 22
    }

    setCellValues(newCellValues)
    setCellValues2(cellValues2)

  }, [])

  useMemo(() => {

    cellValues[1].value1 = cellValues[0].value0 * 100
    cellValues[5].value5 = cellValues[4].value4 * 100
    cellValues[9].value9 = cellValues[8].value8 * 100

    cellValues[3].value3 = cellValues[2].value2 * cellValues[0].value0
    cellValues[7].value7 = cellValues[6].value6 * cellValues[4].value4
    cellValues[11].value11 = cellValues[10].value10 * cellValues[8].value8

    cellValues[12].value12 = parseFloat(cellValues[0].value0) + parseFloat(cellValues[4].value4) + parseFloat(cellValues[8].value8)
    cellValues[13].value13 = parseFloat(cellValues[1].value1) + parseFloat(cellValues[5].value5) + parseFloat(cellValues[9].value9)
    cellValues[15].value15 = parseFloat(cellValues[3].value3) + parseFloat(cellValues[7].value7) + parseFloat(cellValues[11].value11)

  }, [cellValues])

  useMemo(() => {

    cellValues2[2].value2 = parseFloat(cellValues2[0].value0 * cellValues2[1].value1 / 1000)
    cellValues2[5].value5 = parseFloat(cellValues2[3].value3 * cellValues2[4].value4 / 1000)
    cellValues2[8].value8 = parseFloat(cellValues2[2].value2) + parseFloat(cellValues2[5].value5)

  }, [cellValues2])

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        מקדם נגר
      </Typography>
      <Grid >
        {runoffCoefficientTextFields(runoffCoefficient[0], 0)}
        {/* <MyTable
          colHeader={colHeader}
          headers={headers}
          rowTitles={rowTitles}
          cellValues={cellValues}
          setCellValues={setCellValues}
        ></MyTable> */}

        <h3> טבלה מקדם נגר 1</h3>
        <input type="file" onChange={handleChangeImage1} />
        <img alt="" src={runoffCoefficientTableImage1} />
        {/* {runoffCoefficientTextFields(runoffCoefficient[1], 1)} */}
        {/* <MyTable
          colHeader={colHeader2}
          headers={headers2}
          rowTitles={rowTitles2}
          cellValues={cellValues2}
          setCellValues={setCellValues2}
        ></MyTable> */}
        {/* <h3> טבלה מקדם נגר 2</h3>

        <input type="file" onChange={handleChangeImage2} />
        <img alt="" src={runoffCoefficientTableImage2} /> */}
      </Grid>
      <Grid margin={5}>
        <h3> איור מקדם נגר</h3>

        <input type="file" onChange={handleChange} />
        <img alt="" src={runoffCoefficientImage} />
      </Grid>
    </React.Fragment>
  );
}