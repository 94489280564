import React, { useEffect, useState } from 'react';
import RightSidebar from './RightSideBar';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ArticleIcon from '@mui/icons-material/Article';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import { Buffer } from 'buffer'

import Gate from './Gate';
import Background from './Background';
import Resources from './Resources';
import logo from '../smtil.png';
import Conclutions1 from './Conclutions1';
import Copyright from './Copyright';

import '../App.css';
import Topography from './Topography';
import GroundWaterLevel from './GroundWaterLevel';
import Tama1 from './Tama1';
import Probability from './Probability';
import ConclutionsFinal from './ConclutionsFinal';
import MorePictures from './MorePictures';
import Instructions from './Instructions';
import ExistingDrainageSystem from './ExistingDrainageSystem';
import SignIn from './SignIn';
import RunoffCoefficient from './RunoffCoefficient';
import RainPower from './RainPower';
import Sufficiency from './Sufficiency';
import ManageRunoff from './ManageRunoff';
import SuggestedSystem from './SuggestedSystem';
import CalculateRunoff from './CalculateRunoff';

const defaultTheme = createTheme();

export default function Checkout(props) {

  const { step, setStep, steps, connected, setConnected } = props;

  //Gate
  const [head, setHead] = useState('דוח הידרולוגי');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('SMTIL');
  const [date, setDate] = useState('');
  const [adrichal, setAdrichal] = useState('');
  const [projectName, setProjectName] = useState('');
  const [city, setCity] = useState('');
  const [clientName, setClientName] = useState('');
  const [location1, setLocation1] = useState('');
  const [location2, setLocation2] = useState('');

  //Background
  const [backgrounds, setBackgrounds] = useState([{ value: 'התוכנית מציעה' }, { value: 'התוכנית ממוקמת' }, { value: 'שטח התוכנית עומד על ושטחי גגות ומרפסות עומד על' }, '', '']);

  //Resources
  const [resources, setResources] = useState([{ value: 'תכנית מדידה מפורטת של האתר ע"י אאא מתאריך אאא' }, { value: 'דוח קרקע של האתר ע"י אאא מתאריך אאא ' }, { value: 'הוראות, תשריט ונספחי תכנית מספר אאא' }, { value: 'הוראות, תשריט ונספחי תכנית מספר אאא' }, { value: 'תוכנית בינוי של משרד אאא' }, { value: 'הוראות, תשריט ונספחי תמ"א 1' }, { value: 'הנחיות רשות המים במסמך "עקרונות שימר נגר" גרסה 1.4' }, { value: 'הוראות תקן בניה ירוקה 5281' }, { value: 'המדריך לבניה משמרת נגר עילי של משרד השיכון' }]);

  //Conclutuon First
  const [conclutions, setConclutions] = useState([{ value: "בתאריך אאא בוצע באתר קידוחי ניסיון לעומק אאא מטר, להלן הממצאים" }, '', '']);
  const [conclutionImage1, setConclutionImage1] = useState('');

  //Topography
  const [topography, setTopography] = useState([{ value: 'שטח התוכנית נמצא אאא' }, { value: 'הרום המקסימלי בשטח התוכנית עומד על אאא מעל פני הים' }, { value: 'הרום המינימלי בשטח התוכנית עומד על אאא מעל פני הים' }]);
  const [topographyImage, setTopographyImage] = useState('');

  //GroundWaterLevel
  const [groundWaterLevel, setGroundWaterLevel] = useState([{ value: 'ע"פ נתונים של השירות ההידרולוגי, מפלס מי התהום בשטח התכנית עומד על כ-אאא מעל פני הים' }, { value: 'התוכנית מציעה בינוי של אאא קומות מרתף, בעומק חפירה של כ-אאא מטר' }, { value: 'בקידוחי הניסיון אשר בוצעו באתר, אאא נמצאו מים' }]);
  const [groundWaterLevelImage, setGroundWaterLevelImage] = useState('');

  //Tama1
  const [tama1, setTama1] = useState([{ value: 'ע"פ תמ"א 1, שטח התכנית אאא נמצא בקרב נחל אאא' }, { value: 'שטח התוכנית אאא נמצא פשט הצפה' }, { value: 'שטח התוכנית אאא ממוקם באזור בעל חשיבות להחדרת מי נגר לתת הקרקע' }, { value: 'שטח התוכנית אאא ממוקם באזור רגישות להחדרת מי נגר לתת הקרקע' }]);
  const [tama1Image, setTama1Image] = useState('');

  //ExistingDrainageSystem
  const [existingDrainageSystem, setExistingDrainageSystem] = useState([{ value: 'אאא קיים קו ניקוז בקוטר אאא ס"מ ברחוב אאא' }, { value: 'אאא קיים קו ניקוז בקוטר אאא ס"מ ברחוב אאא' }, { value: 'הקווים הנ"ל מתחברים אל קו מאסף בקוטר אאא ברחוב אאא' }]);
  const [existingDrainageSystemImage, setExistingDrainageSystemImage] = useState('');

  //Probability
  const [probability, setProbability] = useState([{ value: '0' }]);

  //RainPower
  const [rainPower, setRainPower] = useState([{ value: 0 }, { value: 'שטח התוכנית נמצא באזור אאא' }]);

  //RunoffCoefficient
  const [runoffCoefficient, setRunoffCoefficient] = useState([[{ value: 'במפות חבורות הקרקע של משרד החקלאות ובאתר Govmap, חבורת הקרקע בסביבת שטח התוכנית הינה מסוג אאא' }, { value: 'לחבורת קרקע זו נקבע במדריך לבניה משמרת נגר מקדם נגר של אאא' }, { value: 'בטבלה להלן מוצגים חישובי מקדם הנגר המשוקלל לשטח התוכנית במצב המתוכנן' }],
  [{ value: "במסגרת התכנית מתוכננת בניה של רב קומות, לגובה של כ-אאא מ'" }, { value: 'חזיתות הבניינים המתוכננים יקלטו נגר במקום השטחים הסובבים אותם' }, { value: 'לכן, נתחשב בתוספת נגר המצטבר בשטח התוכנית בחזיתית הבניינים' }, { value: 'בטבלה להלן מוצגים מידות חזית הבניינים המתוכננים' }, { value: 'נלקח מקדם של 30% שטח החזית כשטח תורם נגר' }]]);

  const [runoffCoefficientCellValues, setRunoffCoefficientCellValues] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }, { value8: '' }, { value9: '' }, { value10: '' }, { value11: '' }, { value12: '' }, { value13: '' }, { value14: '' }, { value15: '' }]);
  const [runoffCoefficientCellValues2, setRunoffCoefficientCellValues2] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }, { value8: '' }, { value9: '' }, { value10: '' }]);
  const [runoffCoefficientImage, setRunoffCoefficientImage] = useState('');
  const [runoffCoefficientTableImage1, setRunoffCoefficientTableImage1] = useState('');
  const [runoffCoefficientTableImage2, setRunoffCoefficientTableImage2] = useState('');

  //Sufficiency
  const [sufficiency, setSufficiency] = useState([{ value: `לפי תיקון 8 לתמ"א 1, יש לתכן מערכת ניקוז וניהול נגר לוויסות ספיקת התכן לספיקה המחושבת לאירוע גשם במשך 60 דקות בהסתברות 20%` }, { value: 'לכן, חושבו ספיקות התכן המתקבלים במגרש באירוע גשם בעוצמה של אאא מ"מ/שעה לפי גשם במשך 10 דקות בהסתברות 20% ובאירוע גשם בעוצמה של אאא מ"מ/שעה לפי גשם במשך 60 דקות בהסתברות 20%, כמוצג בטבלה להלן' }]);
  const [sufficiencyCellValues, setSufficiencyCellValues] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }, { value8: '' }, { value9: '' }, { value10: '' }, { value11: '' }, { value12: '' }, { value13: '' }, { value14: '' }, { value15: '' }, { value16: '' }, { value17: '' }, { value18: '' }]);
  const [sufficiencyTableImage, setSufficiencyTableImage] = useState('');

  //ManageRunoff
  const [manageRunoff, setManageRunoff] = useState([[{ value: 'הוראות התקן מורות על תנאי סף לעמידה בעקרונות הבנייה הירוקה, תנאי של השהייה ו/או אאא' }, { value: 'החדרת 15% ממי הנגר הנוצרים במגרש באירוע גשם במשך 10 דקות ובהסתברות 20% (1:5 שנים)' }, { value: 'על מנת לקבל אאא נקודות יש לטפל בסה"כ %אאא ממי הנגר הנוצרים באירוע הנ"ל' }, { value: 'לצורך קביעת אמצעים לניהול נגר, חושבו כמויות הנגר המתקבלות בחלקי המגרש' }, { value: 'בטבלה שלהלן מוצגים נפחי הנגר המתקבלים בשטח המגרש בהתאם לעוצמת גשם של אאא מ"מ/שעה במשך 10 דקות עם מקדם נגר של אאא (-)' },], [{ value: 'מתקבל שניהול מי נגר בנפח של אאא מ"ק במשך 10 דקות מעניק אאא נקודות לפי תקן בנייה ירוקה עם טיפול בסה"כ %אאא ממי הנגר הנוצרים באירוע הנ"ל' }]])
  const [manageRunoffCellValues, setManageRunoffCellValues] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }, { value8: '' }, { value9: '' }, { value10: '' }, { value11: '' }, { value12: '' }, { value13: '' }, { value14: '' }, { value15: '' }, { value16: '' }, { value17: '' }, { value18: '' }, { value19: '' }, { value20: '' }, { value21: '' }, { value22: '' }, { value23: '' }]);
  const [manageRunoffTableImage, setManageRunoffTableImage] = useState('');

  //SuggestedSystem
  const [suggestedSystem, setSuggestedSystem] = useState([[{ value: 'מתוכנן מערכת החדרה למי נגר עבור מי גגות ומרפסות בלבד, הכוללת שוחת שיקוע וסינון קדם, המתחברת לשוחה עם קידוח החדרה בתחתית' }, { value: 'השוחות יהיו אטומים וללא מכסי רשת' }, { value: 'שוחת השיקוע והשוחה עם קידוח החדרה בתחתית יהיו במידות פנימיות של אאא על אאא עומק, כל שוחה בנפרד בנפח נטו של אאא מ"ק וביחד בנפח נטו של אאא מ"ק' }, { value: 'כניסת מי נגר לשוחת שיקוע וסינון קדם על ידי צמ"ג משטחי הגגות ומרפסות בלבד' }, { value: 'בתחתית התא, לצד קידוח ההחדרה, יותקן ברז יציאה לצורך סילוק מי קיץ למערכת ביוב' }],
  [{ value: 'להלן נתוני תכן למערכת ההחדרה המתוכננת:' }],
  [{ value: 'מידות:' }],
  [{ value: 'קוטר הקידוח: אאא ס"מ' }, { value: 'קוטר צינור הפנימי: אאא ס"מ' }, { value: "עומק הקידוח: אאא מ'" }, { value: "עומק הקטע המחורץ בצינור: אאא מ'" }, { value: "עומק מי תהום: אאא מ'" }],
  [{ value: 'נפח פנוי עבור השהייה:' }],
  [{ value: 'צינור פנימי בקידוח החדרה: אאא מ"ק' }, { value: 'שוחת שיקוע וסינון קדם: אאא מ"ק' }, { value: 'שוחה עם קידוח החדרה בתחתית: אאא מ"ק' }, { value: 'סה"כ נפח פנוי עבור השהייה לכל קידוח החדרה ותא שיקוע: אאא מ"ק' }, { value: 'נפח זה עומד בדרישה של רשות המים' }],
  [{ value: 'ספיקה:' }],
  [{ value: 'מוליכות הידראולית אופיינית לשכבת המטרה בקרקע: אאא מ"מ/שעה' }, { value: 'מקדם בטיחות עבור מוליכות הידראולית: %אאא' }, { value: 'מוליכות הידראולית עם מקדם בטיחות: אאא מ"מ/שעה' }, { value: 'בהתאם לנוסחה מתקבלת ספיקה לקידוח החדרה: אאא מק"ש' }, { value: 'ספיקה זו עומדת בדרישה של רשות המים' }],
  [{ value: 'בנוסף למערכת ההחדרה, נדרש מאגר השהייה בנפח של אאא מ"ק' }, { value: 'מאגר ההשהייה ימוקם אאא לקליטה ואגירה של מי נגר מכלל שטחי המגרש (גגות, מרפסות ופיתוח)' }, { value: 'מאגר ההשהייה ינוקז אאא אל אאא דרך אאא' }, { value: 'ניקוז המאגר מתוכנן אאא בספיקה של אאא מק"ש, לעמידה במגבלה של ספיקת התכן שנקבעה על אאא מק"ש, המתאימה לאירוע גשם במשך 60 דקות בהסתברות 20%' },]])

  //Instructions
  //const [instructionsCellValues, setInstructionsCellValues] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }]);
  //const [instructionsTableImage, setInstructionsTableImage] = useState('');

  //CalculateRunoff
  //const [calculateRunoff, setCalculateRunoff] = useState([{ value: 'מתקבל שיש לאגור מי נגר בנפח של אאא מ"ק עבור אירוע גשם במשך 10 דקות בהסתברות 2%' }, { value: 'קו יציאה למי נגר עודף נדרש בכושר הולכה של אאא מק"ש (אאא מ"ק/שניה), עבור ספיקת תכן המתאימה לאירוע גשם במשך 60 דקות בהסתברות 20%' }, { value: 'לכן, קו היציאה מתוכנן בקוטר אאא מ"מ ושיפוע %אאא, עם כושר הולכה של אאא מ"ק/שניה בדרגת מילוי %אאא, להבטחת סילוק מי נגר עודף בהתאם לספיקה המתוכננת, כפי שניתן לראות באיור להלן' }])
  // const [manageRunoffCellValues, setManageRunoffCellValues] = useState([{ value0: '' }, { value1: '' }, { value2: '' }, { value3: '' }, { value4: '' }, { value5: '' }, { value6: '' }, { value7: '' }, { value8: '' }, { value9: '' }, { value10: '' }, { value11: '' }, { value12: '' }, { value13: '' }, { value14: '' }, { value15: '' }, { value16: '' }, { value17: '' }, { value18: '' }, { value19: '' }]);

  //ConclutuonFinal
  const [conclutionsFinal, setConclutionsFinal] = useState([{ value: 'מערכת ההחדרה המוצעת תקלוט את מי הגגות ומרפסות בלבד הנוצרים בשטח כולל אאא מ"ר מתוך שטח המגרש אאא מ"ר' }, { value: 'לכן, מערכת ההחדרה תטפל בסה"כ %אאא ממי הנגר הנוצרים במגרש' }, { value: 'מערכת ההחדרה המוצעת עומדת בהנחיות רשות המים לתכנון ברמת המגרש, עם יכולת קליטה באיגום של נפח המים המתקבל משטח הגגות ומרפסות באירוע גשם של 10 דקות בהסתברות 20% ועם יכולת החדרה שעתית של נפח המים המתקבל משטח הגגות ומרפסות באירוע גשם בהסתברות 2%' }, {
    value: 'בנוסף, המערכת המוצעת עומדת בתקן בנייה ירוקה מספר 5281 לקבלת אאא נקודות עבור טיפול בסה"כ %אאא ממי הנגר הנוצרים במגרש באירוע גשם במשך 10 דקות בהסתברות 20%'
  }, { value: 'יש לקבל אישור קונסטרוקטור ויועץ הקרקע וביסוס למערכת המוצעת' }, { value: 'יש לתאם את המערכת המוצעת עם יועץ אינסטלציה בתוכנית ניקוז' }, { value: 'שוחת שיקוע ושוחת ההחדרה יהיו עם מכסים אטומים' }, { value: 'שוחת השיקוע תקלוט את הצמ"גים' }, { value: 'בין השוחות יהיה קיר חוצץ, עם חיבור על ידי פתח במפלס עליון של הקיר' }, { value: 'לשוחת ההחדרה יהיה צינור יציאה ממפלס עליון בשוחה עבור גלישת עודפים על ידי שפיכה חופשית אל אבן השפה של הרחוב' }, { value: 'בקידוח החדרה יהיה צינור פנימי אטום מהשוחה ועד לעומק אאא מטר מתחת לתחתית מרתף, לפי הנחיות יועץ ביסוס' }, { value: 'מעומק אאא מטר מתחת לתחתית מרתף ועד לתחתית הקידוח הצינור יהיה מחורץ בקטע של אאא מטר אורך' }, { value: 'מקטע הצינור המחורץ שיושב בתוך השוחה נדרש לגודל חריצים 1 מ"מ, וכן עיטוף בבד גיאוטכני. ראש הצינור נדרש למכסה מחורץ' }, { value: 'גודל חריצים למסננת הקידוח עצמו 1 מ"מ' },
  { value: 'גודל חצץ קוורץ / חול פילטר נדרש ל1.5-2.5 מ"מ' }, { value: 'הצינור המחורץ בתוך הקידוח ללא בד גיאוטכני' }, { value: 'מעל למקטע המחורץ, נדרש מטר צינור אטום מול חצץ קוורץ' }, { value: 'מעליו, לתכנן מטר צינור אטום מול בנטונייט גרגירים' }, { value: 'מעליו, דיוס במלט / בטון עד לשוחה עצמה' }, { value: 'מתחת לקטע המחורץ נדרש מטר צינור אטום' }, { value: 'להלן תוכנית תנוחה ופרט, המכילים את מיקום ומידות של מערכת ההחדרה המוצעת' }]);

  //More Pictures
  const [picturesAndTitles, setPicturesAndTitles] = useState([[{ value0: '' }, { value1: '' }]]);

  const handleNext = () => {
    if (step === steps.length - 1) generateDocument()

    else setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Gate
          head={head}
          date={date}
          address={address}
          companyName={companyName}
          clientName={clientName}
          adrichal={adrichal}
          projectName={projectName}
          city={city}
          location1={location1}
          location2={location2}

          setHead={setHead}
          setDate={setDate}
          setAddress={setAddress}
          setCompanyName={setCompanyName}
          setClientName={setClientName}
          setAdrichal={setAdrichal}
          setProjectName={setProjectName}
          setCity={setCity}
          setLocation1={setLocation1}
          setLocation2={setLocation2}
        />
      case 1:
        return <Background
          backgrounds={backgrounds}
          setBackgrounds={setBackgrounds}
        />
      case 2:
        return <Resources
          resources={resources}
          setResources={setResources}
        />
      case 3:
        return <Conclutions1
          conclutions={conclutions}
          setConclutions={setConclutions}
          conclutionImage1={conclutionImage1}
          setConclutionImage1={setConclutionImage1}
        />;
      case 4:
        return <Topography
          topography={topography}
          setTopography={setTopography}
          topographyImage={topographyImage}
          setTopographyImage={setTopographyImage}
        />;
      case 5:
        return <GroundWaterLevel
          groundWaterLevel={groundWaterLevel}
          setGroundWaterLevel={setGroundWaterLevel}
          groundWaterLevelImage={groundWaterLevelImage}
          setGroundWaterLevelImage={setGroundWaterLevelImage}
        />;
      case 6:
        return <Tama1
          tama1={tama1}
          setTama1={setTama1}
          tama1Image={tama1Image}
          setTama1Image={setTama1Image}
        />;
      case 7:
        return <ExistingDrainageSystem
          existingDrainageSystem={existingDrainageSystem}
          setExistingDrainageSystem={setExistingDrainageSystem}
          existingDrainageSystemImage={existingDrainageSystemImage}
          setExistingDrainageSystemImage={setExistingDrainageSystemImage}
        />;
      case 8:
        return <Probability
          probability={probability}
          setProbability={setProbability}
          region={rainPower[0].value}
        />;
      case 9:
        return <RainPower
          rainPower={rainPower}
          setRainPower={setRainPower}
        />;
      case 10:
        return <RunoffCoefficient
          runoffCoefficient={runoffCoefficient}
          setRunoffCoefficient={setRunoffCoefficient}
          cellValues={runoffCoefficientCellValues}
          setCellValues={setRunoffCoefficientCellValues}
          cellValues2={runoffCoefficientCellValues2}
          setCellValues2={setRunoffCoefficientCellValues2}
          runoffCoefficientImage={runoffCoefficientImage}
          setRunoffCoefficientImage={setRunoffCoefficientImage}
          runoffCoefficientTableImage1={runoffCoefficientTableImage1}
          setRunoffCoefficientTableImage1={setRunoffCoefficientTableImage1}
          runoffCoefficientTableImage2={runoffCoefficientTableImage2}
          setRunoffCoefficientTableImage2={setRunoffCoefficientTableImage2}
          region={rainPower[0].value}
        />;
      case 11:
        return <Sufficiency
          sufficiency={sufficiency}
          setSufficiency={setSufficiency}
          cellValues={sufficiencyCellValues}
          setCellValues={setSufficiencyCellValues}
          region={rainPower[0].value}
          runoffCoefficientCellValues={runoffCoefficientCellValues}
          runoffCoefficientCellValues2={runoffCoefficientCellValues2}
          sufficiencyTableImage={sufficiencyTableImage}
          setSufficiencyTableImage={setSufficiencyTableImage}
        />;
      // case 12: //חישוב מי נגר עודף
      //   return <CalculateRunoff
      //     calculateRunoff={calculateRunoff}
      //     setCalculateRunoff={setCalculateRunoff}
      //   />;
      // case 13:
      //   return <Instructions
      //     cellValues={instructionsCellValues}
      //     setCellValues={setInstructionsCellValues}
      //     instructionsTableImage={instructionsTableImage}
      //     setInstructionsTableImage={setInstructionsTableImage}
      //     region={rainPower[0].value}
      //   />;
      case 12: //ניהול נגר לפי תקן
        return <ManageRunoff
          manageRunoff={manageRunoff}
          setManageRunoff={setManageRunoff}
          cellValues={manageRunoffCellValues}
          setCellValues={setManageRunoffCellValues}
          manageRunoffTableImage={manageRunoffTableImage}
          setManageRunoffTableImage={setManageRunoffTableImage}
          region={rainPower[0].value}
        />;
      case 13: //מערכת מוצעת לניהול נגר
        return <SuggestedSystem
          suggestedSystem={suggestedSystem}
          setSuggestedSystem={setSuggestedSystem}
        />;
      case 14:
        return <ConclutionsFinal
          conclutionsFinal={conclutionsFinal}
          setConclutionsFinal={setConclutionsFinal}
        />;
      case 15:
        return <MorePictures
          picturesAndTitles={picturesAndTitles}
          setPicturesAndTitles={setPicturesAndTitles}
        />;
      default:
        return <Probability
          probability={probability}
          setProbability={setProbability}
        />;
    }
  }

  const generateDocument = async () => {
    try {

      var newbackgrounds = backgrounds
      newbackgrounds = newbackgrounds.filter(item => (item !== ''));

      var newResources = resources
      newResources = newResources.filter(item => (item !== ''));

      var newConclutions = conclutions
      newConclutions = newConclutions.filter(item => (item !== ''));

      var newTopography = topography
      newTopography = newTopography.filter(item => (item !== ''));

      var newGroundWaterLevel = groundWaterLevel
      newGroundWaterLevel = newGroundWaterLevel.filter(item => (item !== ''));

      var newTama1 = tama1
      newTama1 = newTama1.filter(item => (item !== ''));

      var newExistingDrainageSystem = existingDrainageSystem
      newExistingDrainageSystem = newExistingDrainageSystem.filter(item => (item !== ''));

      var newProbability = probability
      newProbability = newProbability.filter(item => (item !== ''));

      var newRunoffCoefficientCellValues = []

      var newRunoffCoefficientCellValues0 = runoffCoefficientCellValues[0]
      for (let i = 0; i < runoffCoefficientCellValues.length - 1; i++) {
        var newRunoffCoefficientCellValues1 = runoffCoefficientCellValues[i + 1]

        newRunoffCoefficientCellValues0 = Object.assign(newRunoffCoefficientCellValues0, newRunoffCoefficientCellValues1)
      }
      newRunoffCoefficientCellValues.push(newRunoffCoefficientCellValues0)

      var newRunoffCoefficientCellValues2 = []

      var newRunoffCoefficientCellValues20 = runoffCoefficientCellValues2[0]
      for (let i = 0; i < runoffCoefficientCellValues2.length - 1; i++) {
        var newRunoffCoefficientCellValues21 = runoffCoefficientCellValues2[i + 1]

        newRunoffCoefficientCellValues20 = Object.assign(newRunoffCoefficientCellValues20, newRunoffCoefficientCellValues21)
      }
      newRunoffCoefficientCellValues2.push(newRunoffCoefficientCellValues20)

      var newSufficiency = sufficiency
      newSufficiency = newSufficiency.filter(item => (item !== ''));

      var newSufficiencyCellValues = []

      var newSufficiencyCellValues0 = sufficiencyCellValues[0]
      for (let i = 0; i < sufficiencyCellValues.length - 1; i++) {
        var newSufficiencyCellValues1 = sufficiencyCellValues[i + 1]

        newSufficiencyCellValues0 = Object.assign(newSufficiencyCellValues0, newSufficiencyCellValues1)
      }
      newSufficiencyCellValues.push(newSufficiencyCellValues0)

      // var newInstructionsCellValues = []

      // var newInstructionsCellValues0 = instructionsCellValues[0]
      // for (let i = 0; i < instructionsCellValues.length - 1; i++) {
      //   var newInstructionsCellValues1 = instructionsCellValues[i + 1]

      //   newInstructionsCellValues0 = Object.assign(newInstructionsCellValues0, newInstructionsCellValues1)
      // }
      // newInstructionsCellValues.push(newInstructionsCellValues0)

      var newManageRunoffCellValues = []

      var newManageRunoffCellValues0 = manageRunoffCellValues[0]
      for (let i = 0; i < manageRunoffCellValues.length - 1; i++) {
        var newManageRunoffCellValues1 = manageRunoffCellValues[i + 1]

        newManageRunoffCellValues0 = Object.assign(newManageRunoffCellValues0, newManageRunoffCellValues1)
      }
      newManageRunoffCellValues.push(newManageRunoffCellValues0)

      var newConclutionsFinal = conclutionsFinal
      newConclutionsFinal = newConclutionsFinal.filter(item => (item !== ''));

      var newPicturesAndTitles = []

      for (let i = 0; i < picturesAndTitles.length; i++) {
        var picturesAndTitles0 = ""
        var picturesAndTitles1 = ""
        for (let j = 0; j < picturesAndTitles[i].length; j++) {
          picturesAndTitles0 = picturesAndTitles[i][0];
          picturesAndTitles1 = picturesAndTitles[i][1];
        }
        newPicturesAndTitles.push(Object.assign(picturesAndTitles0, picturesAndTitles1))
      }

      const requestBody = {
        head,
        date,
        address,
        companyName,
        clientName,
        adrichal,
        projectName,
        city,
        location1,
        location2,
        newbackgrounds,
        newResources,
        newConclutions,
        conclutionImage1,
        newTopography,
        topographyImage,
        newGroundWaterLevel,
        newTama1,
        tama1Image,
        newExistingDrainageSystem,
        existingDrainageSystemImage,
        newProbability: newProbability[0].value,
        rainPower0: rainPower[0].value,
        rainPower1: rainPower[1].value,
        runoffCoefficient0: runoffCoefficient[0],
        runoffCoefficientCellValues0: newRunoffCoefficientCellValues,
        runoffCoefficientTableImage1: runoffCoefficientTableImage1,
        runoffCoefficient1: runoffCoefficient[1],
        runoffCoefficientCellValues1: newRunoffCoefficientCellValues2,
        runoffCoefficientTableImage2: runoffCoefficientTableImage2,
        runoffCoefficientImage: runoffCoefficientImage,
        newSufficiency,
        sufficiencyCellValues: newSufficiencyCellValues,
        sufficiencyTableImage: sufficiencyTableImage,
        manageRunoff0: manageRunoff[0],
        manageRunoffCellValues: newManageRunoffCellValues,
        manageRunoffTableImage,
        manageRunoff1: manageRunoff[1],
        suggestedSystem0: suggestedSystem[0],
        suggestedSystem3: suggestedSystem[3],
        suggestedSystem5: suggestedSystem[5],
        suggestedSystem7: suggestedSystem[7],
        suggestedSystem8: suggestedSystem[8],
        // calculateRunoff,
        newConclutionsFinal,
        picturesAndTitles: newPicturesAndTitles,
        // cellValues: newInstructionsCellValues,
        //instructionsTableImage: instructionsTableImage,
      };
      const response = await fetch('https://repgen.co.il/document', { //https://www.repgen.co.il/document http://localhost:5000/document
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      const buffer = Buffer.from(data);
      const blob = new Blob([buffer]);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = head + ".docx";
      a.click();


    } catch (error) {

      console.error('Error:', error);
      // Handle the error as needed

    }
  };

  const saveData = () => {

    const myData = {
      "head": head, "date": date, "address": address, "companyName": companyName, "clientName": clientName, "adrichal": adrichal, "projectName": projectName, "city": city, "location1": location1, "location2": location2,
      "backgrounds": backgrounds,
      "resources": resources,
      "conclutions": conclutions,
      "topography": topography,
      "groundWaterLevel": groundWaterLevel,
      "tama1": tama1,
      "existingDrainageSystem": existingDrainageSystem,
      "rainPower": rainPower,
      "runoffCoefficient": runoffCoefficient,
      "sufficiency": sufficiency,
      "probability": probability,
      "manageRunoff": manageRunoff,
      "conclutionsFinal": conclutionsFinal,
      "runoffCoefficientCellValues0": runoffCoefficientCellValues,
      "runoffCoefficientCellValues1": runoffCoefficientCellValues2,
      "sufficiencyCellValues": sufficiencyCellValues,
      "manageRunoffCellValues": manageRunoffCellValues,
      "suggestedSystem": suggestedSystem,
      // "calculateRunoff": calculateRunoff,
      // "instructionsCellValues": instructionsCellValues,
    }
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = head + ".json";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const importData = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      var data = JSON.parse(e.target.result)

      setHead(data.head)
      setDate(data.date)
      setAddress(data.address)
      setCompanyName(data.companyName)
      setClientName(data.clientName)
      setAdrichal(data.adrichal)
      setProjectName(data.projectName)
      setCity(data.city)
      setLocation1(data.location1)
      setLocation2(data.location2)
      setBackgrounds(data.backgrounds)
      setResources(data.resources)
      setConclutions(data.conclutions)
      setTopography(data.topography)
      setGroundWaterLevel(data.groundWaterLevel)
      setTama1(data.tama1)
      setExistingDrainageSystem(data.existingDrainageSystem)
      setRainPower(data.rainPower)
      setRunoffCoefficient(data.runoffCoefficient)
      setSufficiency(data.sufficiency)
      setProbability(data.probability)
      setConclutionsFinal(data.conclutionsFinal)
      setRainPower(data.rainPower)
      setRunoffCoefficientCellValues(data.runoffCoefficientCellValues0)
      setRunoffCoefficientCellValues2(data.runoffCoefficientCellValues1)
      setSufficiencyCellValues(data.sufficiencyCellValues)
      setManageRunoffCellValues(data.manageRunoffCellValues)
      setSuggestedSystem(data.suggestedSystem)
      //setCalculateRunoff(data.calculateRunoff)
      //setInstructionsCellValues(data.instructionsCellValues)

    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      saveData()
    }, 300000);
    return () => clearInterval(interval);
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {!connected ? <SignIn setConnected={setConnected}></SignIn> : <div>
        <RightSidebar
          step={step}
          setStep={setStep}
          steps={steps}
        ></RightSidebar>
        <AppBar
          position="absolute"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}>
          <Toolbar>
            <img src={logo} alt="logo" />
            <div >
              <Typography variant="h4" color="blueviolet" noWrap>
                RepGen
              </Typography>
              <Button>Version 1.0.0</Button>
            </div>

            <Box sx={{ '& > button': { m: 2 } }} >
              <LoadingButton spacing={3}
                color="info"
                component="label"
                startIcon={<UploadFileSharpIcon />}
                sx={{ marginRight: "1rem" }}
                variant="contained"
              >
                <span> העלה נתונים</span>
                <input type="file" accept=".json" hidden onChange={(e) => importData(e)} />
              </LoadingButton>
              <LoadingButton spacing={3} component="label"
                color="secondary"
                onClick={() => saveData()}
                startIcon={<SaveIcon />}
                sx={{ marginRight: "1rem" }}
                variant="contained"
              >
                <span>שמור נתונים</span>
              </LoadingButton>
              <LoadingButton spacing={3} component="label"
                color="success"
                onClick={generateDocument}
                startIcon={<ArticleIcon />}
                sx={{ marginRight: "1rem" }}
                variant="contained"
              >
                <span >הורד דוח</span>
              </LoadingButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Container component="main" >
          <Paper variant="outlined">
            <Typography component="h1" variant="h4" align="center">
              מילוי דו"ח
            </Typography>
            <React.Fragment>
              {getStepContent(step)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {step !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    חזור
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {step === steps.length - 1 ? 'ייצור דוח' : 'הבא'}
                </Button>
              </Box>
            </React.Fragment>
          </Paper>
          <Copyright />
        </Container>
      </div>}
    </ThemeProvider>
  );
}