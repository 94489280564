import * as React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import TextFieldButtons from './TextFieldButtons';
import convertBase64 from '../Utils';

import { useStyles } from '../style';

export default function ExistingDrainageSystem(props) {
  const { existingDrainageSystem, setExistingDrainageSystem, existingDrainageSystemImage, setExistingDrainageSystemImage } = props
  const classes = useStyles();

  const handleChange = async (e) => {
    const base64 = await convertBase64(e.target.files[0])
    setExistingDrainageSystemImage(base64);
  }

  const setExistingDrainageSystemValue = (index, value) => {
    var newExistingDrainageSystem = [...existingDrainageSystem]
    newExistingDrainageSystem[index] = { ...existingDrainageSystem[index], value }
    setExistingDrainageSystem(newExistingDrainageSystem)
  }

  const existingDrainageSystemTextFields = () => {
    return (existingDrainageSystem.map((item, index) => (
      <Grid item xs={12} key={index}>
        <TextField
          required
          className={classes.textField}
          key={index}
          id="address1"
          name="address1"
          label="תיאור"
          fullWidth
          autoComplete="shipping address-line1"
          variant="standard"
          onChange={(e) => setExistingDrainageSystemValue(index, e.target.value)}
          value={item.value}
        />
      </Grid>
    )))
  }

  return (
    <React.Fragment >
      <Typography variant="h5" gutterBottom>
        מערכת ניקוז קיימת
      </Typography>
      <Grid container spacing={3}>
        {existingDrainageSystemTextFields()}
        <Grid item xs={12}>
          <input type="file" onChange={handleChange} />
          <img alt="" src={existingDrainageSystemImage} />
        </Grid>
      </Grid>
      <TextFieldButtons
        arr={existingDrainageSystem}
        setArr={setExistingDrainageSystem}
      />
    </React.Fragment>
  );
}